<template>
  <section class="back-to-shop">
    <p>{{ $t('confirmation.linkBackTimeout') }} {{redirectTime}} s</p>
    <button type="button"
            ref="backButton"
            class="btn button-back-to-shop"
            :title="$t('confirmation.linkBack')"
            @click="goBackToShop">
      {{ $t('confirmation.linkBack') }}
    </button>
    <input type="hidden" style="margin: 10px;" id="iframefocus" value="">
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsClass } from '@/base/class/tools.class'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import Vue from 'vue'
import { CONFIRMATION_REDIRECT_TIME } from '@/base/const/confirmation.const'

export default {
  name: 'PaywayErrorButton',
  data () {
    return {
      redirectHandler: null,
      redirectTime: Vue.prototype.$VUE_CONFIRMATION_TIMER ? Vue.prototype.$VUE_CONFIRMATION_TIMER : CONFIRMATION_REDIRECT_TIME
    }
  },
  computed: {
    ...mapState({
      returnUrl: state => state.payment.paymentData.returnUrl || ''
    })
  },
  methods: {
    goBackToShop () {
      this.stopRedirectTimeout()
      if (ToolsClass.isMobileAppConnection()) {
        MobileAppClass.callMobileTransactionFinish()
        return
      }
      ToolsClass.goToExternalUrl(this.returnUrl)
    },
    stopRedirectTimeout () {
      if (this.redirectHandler) {
        clearTimeout(this.redirectHandler)
      }
    },
    startRedirectTimeout () {
      this.redirectHandler = setTimeout(this.checkRedirectTimeout, 1000)
    },
    checkRedirectTimeout () {
      if (this.redirectTime === 1) {
        this.goBackToShop()
        return
      }
      this.redirectTime--
      this.startRedirectTimeout()
    }
  },
  mounted () {
    this.startRedirectTimeout()
    const survey = document.getElementById('yourcx.insert')
    if (survey) {
      survey.addEventListener('mouseover', () => {
        this.stopRedirectTimeout()
      })
      survey.addEventListener('mouseout', () => {
        this.startRedirectTimeout()
      })
    }
    this.$refs['backButton'].addEventListener('focus', () => {
      this.stopRedirectTimeout()
    })
  }
}
</script>
